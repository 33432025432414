import React from "react";
import ArrowSm from "../assets/images/arrow/Arrow_Hyperlink-16_16px.svg";
import ArrowSmEx from "../assets/images/arrow/Arrow_Hyperlink-16-Extended_32px.svg";
import ArrowLo from "../assets/images/arrow/Arrow_Hyperlink-36_48px.svg";
import ArrowLoEx from "../assets/images/arrow/Arrow_Hyperlink-36-Extended_64px.svg";
import ArrowDown from "../assets/images/arrow/Arrow_Download-16_14px.svg";

export const ArrowSmall = () => {
	return <ArrowSm />;
};

export const ArrowSmallEx = () => {
	return <ArrowSmEx />;
};

export const ArrowLong = () => {
	return <ArrowLo />;
};

export const ArrowLongEx = () => {
	return <ArrowLoEx />;
};

export const ArrowDownload = () => {
	return <ArrowDown />;
};
