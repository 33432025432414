import React from "react";
import Image from "gatsby-image";
import { graphql, Link } from "gatsby";

import Layout from "components/Layout";
import Container from "components/Container";
import Intro from "components/Intro";
import { ArrowSmallEx } from "components/Arrow";
import { SEO } from "components/SEO";
import styled from "styled-components";

const LinkContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 0.75rem;
	margin: 0;
	padding: 0;
	list-style: none;

	@media (min-width: 40.01px) and (max-width: 66.38rem) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 40rem) {
		grid-template-columns: 1fr;
	}
`;

const LinkBox = styled(Link)`
	padding: 1.063rem 1.38rem 0.94rem;
	border: 0.12rem solid var(--gray24);

	.link-wrap {
		overflow: hidden;
	}

	svg {
		margin-right: 0.5rem;
	}

	span {
		display: inline-block;
	}

	svg,
	span {
		transition: transform 200ms ease-out;
		transform: translateX(-16px);
		will-change: transform;
	}

	&:hover,
	&:focus {
		svg,
		span {
			transform: translateX(0);
		}
	}
`;

const ProductsPage = ({ data }) => {
	return (
		<Layout pageName="products">
			<SEO
				title="Blue Medical: Products"
				description="Packaging medical devices, packaging development, sterilizations and validations for the medical technology and healthcare industry"
			/>

			<Container sectionName="products-hero">
				<Image
					fluid={data.productsHeader.childImageSharp.fluid}
					alt="Two Blue Medical employees handling a packaging machine."
				/>
			</Container>
			<Intro className="products-intro">
				<h1 className="mono">The reliable medical packaging partner</h1>
				<p>
					Patient safety is the highest priority. Our services are
					accordingly designed, embracing the entire value chain and
					taking into consideration the increasing cost pressure on
					hospitals and healthcare.
				</p>

				<LinkContainer>
					<LinkBox to="/products/#packaging-development">
						<div className="link-wrap">
							<ArrowSmallEx />
							<span>Packaging Development</span>
						</div>
					</LinkBox>

					<LinkBox to="/products/#medical-devices">
						<div className="link-wrap">
							<ArrowSmallEx />
							<span>Packing Medical Devices</span>
						</div>
					</LinkBox>
					<LinkBox to="/products/#sterilization">
						<div className="link-wrap">
							<ArrowSmallEx />
							<span>Sterilization</span>
						</div>
					</LinkBox>

					<LinkBox to="/products/#validations">
						<div className="link-wrap">
							<ArrowSmallEx />
							<span>Validations</span>
						</div>
					</LinkBox>
					<LinkBox to="/products/#warehouse-logistics">
						<div className="link-wrap">
							<ArrowSmallEx />
							<span>Warehouse & Logistics</span>
						</div>
					</LinkBox>

					<LinkBox to="/products/#combined-products">
						<div className="link-wrap">
							<ArrowSmallEx />
							<span>Combined Products</span>
						</div>
					</LinkBox>
				</LinkContainer>
			</Intro>

			<Container sectionName="infobox-wrapper">
				<div className="infobox" id="products-development">
					<Image
						fluid={data.packagingProcess1.childImageSharp.fluid}
						alt="Medical products with outer packaging on packaging machine."
					/>
					<div className="infobox__text-wrapper infobox__text-wrapper--blue">
						<h1 className="infobox__heading mono">
							Packaging Development
						</h1>
						<ul className="infobox__list copy-large">
							<li className="infobox__list-item">
								Develop Packaging Concept
							</li>
							<li className="infobox__list-item">
								Label Design & Printing
							</li>
							<li className="infobox__list-item">
								Assembly & Testing
							</li>
						</ul>
						<p className="infobox__copy">
							Our solutions focus on usability, functionality and
							compliance with regulatory requirements. Sterile
							packaging systems in medical technology can be
							designed as single or multiple barriers and can be
							expanded into a complete logistics-friendly
							packaging system.
						</p>
					</div>
				</div>

				<div className="infobox infobox--reverse" id="medical-devices">
					<div className="infobox__text-wrapper infobox__text-wrapper--gray12">
						<h1 className="infobox__heading mono">
							Packing Medical Devices
						</h1>
						<ul className="infobox__list copy-large">
							<li className="infobox__list-item">
								Packaging Material Sourcing
							</li>
							<li className="infobox__list-item">
								Cleaning & Rinsing
							</li>
							<li className="infobox__list-item">
								Quality Inspections
							</li>
							<li className="infobox__list-item">Packaging</li>
						</ul>
						<p className="infobox__copy">
							After production, medical devices must be subjected
							to a suitable cleaning and disinfection process to
							remove microbiological impurities and germs. Various
							packaging solutions can then be applied, such as
							blister, pouches or vials.
						</p>
					</div>
					<Image
						fluid={data.packagingProcess2.childImageSharp.fluid}
						alt="Medical screw in blister packaging."
					/>
				</div>

				<div className="infobox" id="sterilization">
					<Image
						fluid={data.packagingProcess3.childImageSharp.fluid}
						alt="Blue Medical employee sterilizing medical devices."
					/>
					<div className="infobox__text-wrapper infobox__text-wrapper--gray42">
						<h1 className="infobox__heading mono">Sterilization</h1>
						<ul className="infobox__list copy-large">
							<li className="infobox__list-item">
								Managing Contract Sterilization Providers (ETO,
								Gamma)
							</li>
							<li className="infobox__list-item">
								Sterilization Protocols
							</li>
							<li className="infobox__list-item">
								Auditing Sterilization Suppliers
							</li>
						</ul>
						<p className="infobox__copy">
							The pre-packaged and coded medical products are
							sterilized by a certified company according to the
							requirements. All common procedures are available.
							The material properties of medical devices are the
							most important factor in the selection of the
							appropriate sterilization process.
						</p>
					</div>
				</div>
				<div className="infobox infobox--reverse" id="validations">
					<div className="infobox__text-wrapper infobox__text-wrapper--blue">
						<h1 className="infobox__heading mono">Validations</h1>
						<ul className="infobox__list copy-large">
							<li className="infobox__list-item">
								Cleaning Validation
								<span>
									particles, chemical residues, microbial
									contamination, etc.
								</span>
							</li>
							<li className="infobox__list-item">
								Packaging Process Validation
								<span>
									tightness, seal strength, burst test etc.
								</span>
							</li>
							<li className="infobox__list-item">
								Sterilization Validation
								<span>
									proof of the efficiency of sterilization at
									different loads and dose ranges
								</span>
							</li>
							<li className="infobox__list-item">
								Biocompatibility
								<span>
									proof that the medical device and packaging
									do not cause mutual, possibly undesired
									reactions to the patient's organism
								</span>
							</li>
							<li className="infobox__list-item">
								Stability Studies
								<span>
									shelf life over the entire storage period of
									a medical device
								</span>
							</li>
							<li className="infobox__list-item">
								Transport Validation
								<span>
									simulation of impacts due to transport,
									fall, climate etc.
								</span>
							</li>
						</ul>
						<p className="infobox__copy">
							These procedures include proof of efficiency,
							steriliza&shy;tion at different loads and dose
							ranges, securing biocompatibility of the medical
							device and packaging, as well as shelf life and
							transportation stability.
						</p>
					</div>
					<Image
						fluid={data.packagingProcess4.childImageSharp.fluid}
						alt="Blue Medical employee using double door system between cleanrooms of different safety levels."
					/>
				</div>
				<div className="infobox" id="warehouse-logistics">
					<Image
						fluid={data.packagingProcess5.childImageSharp.fluid}
						alt="Dozens of packaged medical products in climate-controlled warehouse."
					/>
					<div className="infobox__text-wrapper infobox__text-wrapper--gray12">
						<h1 className="infobox__heading mono">
							Warehouse & Logistics
						</h1>
						<ul className="infobox__list copy-large">
							<li className="infobox__list-item">
								Climate-Controlled Warehouse (GMP)
							</li>
							<li className="infobox__list-item">
								Supply Chain Management
							</li>
							<li className="infobox__list-item">
								International Shipping
							</li>
						</ul>
						<p className="infobox__copy">
							We offer GMP and climate-controlled warehouse to
							ensure that products are handled and stored
							properly, while appropriate documentation is
							maintained. If desired, we also handle your
							warehouse and logistics processes.
						</p>
					</div>
				</div>
				<div
					className="infobox infobox--reverse"
					id="combined-products"
				>
					<div className="infobox__text-wrapper infobox__text-wrapper--gray42">
						<h1 className="infobox__heading mono">
							Combined Products
						</h1>
						<ul className="infobox__list copy-large">
							<li className="infobox__list-item">
								Packaging combined products as defined by
								Medical Device Regulation (MDR)
							</li>
						</ul>

						<p className="infobox__copy">
							Different requirements, one solution: when a drug
							and a medical device are combined as a single unit,
							or a new indication-specific medical device is
							created by combining different medical devices, as
							defined by Medical Device Regulation (MDR), Blue
							Medical can provide the necessary regulatory
							framework to package and market your product.
						</p>
					</div>
					<Image
						fluid={data.packagingProcess6.childImageSharp.fluid}
						alt="Blue Medical employee packaging medical products in cleanroom."
					/>
				</div>
			</Container>
			<Container sectionName="products-cta">
				<p className="copy-large">
					We provide extensive consulting services and would like to
					figure out which solutions are best suited to your needs
				</p>
				<Link to="/contact/">
					<button>Get in Touch</button>
				</Link>
			</Container>
		</Layout>
	);
};

export default ProductsPage;

export const fluidImage = graphql`
	fragment fluidImage on File {
		childImageSharp {
			fluid(maxWidth: 984) {
				...GatsbyImageSharpFluid
			}
		}
	}
`;

export const pageQuery = graphql`
	query {
		productsHeader: file(
			relativePath: { eq: "products/BM_Products_Header_2928x720.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 1464) {
					...GatsbyImageSharpFluid
				}
			}
		}

		packagingProcess1: file(
			relativePath: { eq: "products/BM_Packaging-Process_1_1968x960.jpg" }
		) {
			...fluidImage
		}

		packagingProcess2: file(
			relativePath: { eq: "products/BM_Packaging-Process_2_1968x960.jpg" }
		) {
			...fluidImage
		}
		packagingProcess3: file(
			relativePath: { eq: "products/BM_Packaging-Process_3_1968x960.jpg" }
		) {
			...fluidImage
		}
		packagingProcess4: file(
			relativePath: {
				eq: "products/BM_Packaging-Process_4_1968x1440.jpg"
			}
		) {
			...fluidImage
		}
		packagingProcess5: file(
			relativePath: { eq: "products/BM_Packaging-Process_5_1968x960.jpg" }
		) {
			...fluidImage
		}
		packagingProcess6: file(
			relativePath: { eq: "products/BM_Packaging-Process_6_1968x960.jpg" }
		) {
			...fluidImage
		}
	}
`;
